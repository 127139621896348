import React, { useEffect, useRef } from 'react';
import '../css/visit.scss';
import Layout2 from '../components/Layout2';

const Visit = () => {
    const iframeRef = useRef(null); 

    useEffect(() => {
        const handleResize = () => {
           
            if (iframeRef.current) {
                const viewportHeight = window.innerHeight;
                iframeRef.current.style.height = `${viewportHeight * 0.8}px`; 
            }
        };

        handleResize();  // Call handleResize on mount to set initial height
        window.addEventListener('resize', handleResize);  // Add event listener to resize

        return () => {
            window.removeEventListener('resize', handleResize);  
        };
    }, []); 

    return (
        <Layout2 title="Visitor Access Request" description="Visitor Access Request">
            <div className="visit-contact-wrapper" style={{ width: '100%', overflow: 'hidden' }}>
                <iframe
                    ref={iframeRef} 
                    style={{
                        width: '100%',
                        minHeight: '60vh', 
                        height: '80vh',  
                        border: 'none',
                        maxWidth: '100%',
                        maxHeight: '100vh'
                    }}
                    src="https://forms.office.com/r/UbA6698ZHE?embed=true"
                    frameborder="0"
                    marginwidth="0"
                    marginheight="0"
                    allowFullScreen
                    webkitAllowFullScreen
                    mozAllowFullScreen
                    msAllowFullScreen
                ></iframe>
            </div>
        </Layout2>
    );
}

export default Visit;
